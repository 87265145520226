require('bootstrap-sass');

import Echo from 'laravel-echo';
// import Vue from 'vue';
import pwiSentry from './modules/pwi-sentry';

window.io = require('socket.io-client');

if (typeof window.io !== 'undefined' && window.location.hostname == 'projectworldimpact.com') {
    window.Echo = new Echo({
        broadcaster: 'socket.io',
        host: window.location.hostname + ':6001'
    });
}

window.axios = require('axios');
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
// window.Vue = Vue;

pwiSentry.init();